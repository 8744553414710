<template>
  <div>
    <div class="topSearch">
      <el-form inline v-if="searchConfig.showSearch">
        <el-form-item v-if="searchConfig.showWare">
<!--          <el-select placeholder="仓库" v-model="queryParam.warehouseId" @change="$forceUpdate()">-->
<!--            <el-option v-for="item in warehouseList" :key="item.id" :label="item.name" :value="item.id"></el-option>-->
<!--          </el-select>-->
          <el-cascader
              placeholder="请选择仓库"
              v-model="queryParam.ids"
              :options="warehouseList"
              :props="{ checkStrictly: true ,value: 'id',label: 'name'}"
              @change="selectWarehouse"
              clearable>
          </el-cascader>
        </el-form-item>

        <!-- <el-form-item>
          <el-cascader
              v-model="queryParam.classId"
              :options="classList"
              :props="{ checkStrictly: true ,value: 'id',label: 'name'}"
              clearable></el-cascader>
        </el-form-item>

        <el-form-item v-if="searchConfig.showStatus">
          <el-select placeholder="状态" v-model="queryParam.status" clearable>
            <el-option v-for="(value,key) in statusList" :key="key" :label="value" :value="key"></el-option>
          </el-select>
        </el-form-item> -->
        <el-button v-if="searchConfig.showWare" @click="initList()">搜索</el-button>
      </el-form>
    </div>
    <el-table :data="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange" :currentPage="queryParam.page" :total="total"
                 >
        <el-table-column prop="className" label="分类" align="center"/>
        <el-table-column label="总入库数" align="center">
          <template slot-scope="scope">
             {{scope.row.inBoxNum||0}}箱+{{scope.row.inPackNum||0}}盒
          </template>
        </el-table-column>
        <el-table-column label="总出库数" align="center">
          <template slot-scope="scope">
             {{scope.row.outBoxNum||0}}箱+{{scope.row.outPackNum||0}}盒
          </template>
        </el-table-column>
        <el-table-column label="现库存数" align="center">
          <template slot-scope="scope">
             {{scope.row.boxNum}}箱+{{scope.row.packNum}}盒
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="detail(scope.row)" type="text">详情</el-button>
          </template>
        </el-table-column>
    </el-table>
    <el-dialog :visible.sync="detailDialogShow" :modal-append-to-body="false" append-to-body width="60%">
      <warehouse-detail ref="detail"/>
    </el-dialog>
  </div>
</template>
<script>
import commTable from "@/mixins/commTable";
import commonTable from "@/components/common/commonTable.vue";
import {get} from "@/utils/request";
import PackDetail from "@/pages/dashboard/components/detailDialog/PackDetail.vue";
import PageTimeline from "@/pages/dashboard/components/detailDialog/PageTimeline.vue";
import WarehouseDetail from "@/pages/dashboard/components/detailDialog/WarehouseDetail.vue";

export default {
  components: {WarehouseDetail},
  mixins: [commTable],
  props:{
    searchConfig:{
       type: Object,
      default:()=> {
        return {
          showSearch: true,
          showWare:true,
          showStatus: true,
          showClass: true
        }
      }
    }
  },
  data() {
    return {
      warehouseList:[],
      classList: [],
      classId:'',
      detailDialogShow: false
    }
  },
  mounted() {
    //this.initList()
    this.initClassList()
    if(this.searchConfig.showWare){
      this.initWarehouseList()
    }
  },
  methods: {
    initList(){
      get('/crk/app/warehouseClassDetail',this.queryParam).then(res=>{
        this.tableData=res
      })
    },
    initWarehouseList() {
      get('/crk/app/warehouseTree').then(res=>{
        this.warehouseList = res
        this.queryParam.status = 1
      })
    },
    search() {

    },
    detail(row){
       this.detailDialogShow=true;
       this.$nextTick(()=>{
         this.$refs.detail.queryParam = {}
         if( 0===row.type && this.queryParam.warehouseId ){
           this.$refs.detail.queryParam.warehouseId=this.queryParam.warehouseId
         }else if( 1===row.type && this.queryParam.regionId ){
           this.$refs.detail.queryParam.regionId=this.queryParam.regionId
         }else if( 2===row.type && this.queryParam.localId ){
           this.$refs.detail.queryParam.localId=this.queryParam.localId
         }
         this.$refs.detail.queryParam.status = 1
         this.$refs.detail.queryParam.classList=[row.id]
         this.$refs.detail.queryParam.classId=[row.id]
         this.$refs.detail.queryParam.pageNum = 1
         this.$refs.detail.initList()
       })


    },
    initClassList() {
      get('/pageClass/tree', {type: 0}).then(res => {
        this.classList = res
      })
    },
    selectWarehouse(row){
      if( this.queryParam.warehouseId ){
        delete this.queryParam.warehouseId
      }
      if( this.queryParam.regionId ){
        delete this.queryParam.regionId
      }
      if( this.queryParam.localId ){
        delete this.queryParam.localId
      }

      switch (row.length){
        case 1:
          this.queryParam.warehouseId = row[0]
          break;
        case 2:
          this.queryParam.regionId = row[1]
          break;
        case 3:
          this.queryParam.localId = row[2]
          break;
      }
    }
  }
}
</script>
<style scoped>
  @import "../../../../static/css/crk.css";
  .topSearch{
    padding-top: 10px;
  }
</style>
