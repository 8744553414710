<template>
  <div>
    <el-form inline v-if="searchConfig.showSearch">
      <el-form-item v-if="searchConfig.showWare">
<!--        <el-select placeholder="仓库" v-model="queryParam.warehouseId" @change="$forceUpdate()">-->
<!--          <el-option v-for="item in warehouseList" :key="item.id" :label="item.name" :value="item.id"></el-option>-->
<!--        </el-select>-->
        <el-cascader
            placeholder="请选择仓库"
            v-model="queryParam.ids"
            :options="warehouseList"
            :props="{ checkStrictly: true ,value: 'id',label: 'name'}"
            @change="selectWarehouse"
            clearable>
        </el-cascader>
      </el-form-item>

      <el-form-item>
        <el-cascader
            placeholder="请选择分类"
            v-model="queryParam.classId"
            :options="classList"
            :props="{ checkStrictly: true ,value: 'id',label: 'name'}"
            clearable></el-cascader>
      </el-form-item>

      <el-form-item v-if="searchConfig.showStatus">
        <el-select placeholder="状态" v-model="queryParam.status" clearable>
          <el-option v-for="(value,key) in statusList" :key="key" :label="value" :value="key"></el-option>
        </el-select>
      </el-form-item>
      <el-button @click="initList()">搜索</el-button>
    </el-form>
    <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange" :currentPage="queryParam.pageNum" :total="total"
                 :row-class-name="tableRowClassName">
      <template slot="table">
        <el-table-column prop="className" label="类型" align="center"/>
        <el-table-column prop="handle" label="标识" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.handle }}</span>
            <span :class="scope.row.isBox ? 'box' : 'pack'">{{
                scope.row.isBox ? '箱' : '盒'
              }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="inTime" label="入库时间" align="center"></el-table-column>
        <el-table-column prop="warehouseName" label="位置" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.warehouseName }}</span>
            <span v-if="scope.row.regionName">/{{ scope.row.regionName }}</span>
            <span v-if="scope.row.localName">/{{ scope.row.localName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="剩余盒数/总盒数" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.isBox">
              <el-button type="text" @click="showDetail(scope.row,1)">{{ scope.row.packNum }}</el-button>/<el-button type="text" @click="showDetail(scope.row)">{{scope.row.allPackNum }}</el-button></span>
            <span v-else>
              <!-- @click="showDetail(scope.row,1)" -->
              <el-button style="color: #000;" type="text" >1</el-button>/<el-button style="color: #000;" type="text" >1</el-button>
              <!-- @click="showDetail(scope.row)" -->
            </span>
          </template>
        </el-table-column>
<!--        <el-table-column prop="inTime" label="入库时间" align="center"/>-->
<!--        <el-table-column prop="dealerName" label="代理商" align="center"/>-->
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button @click="detail(scope.row)"  type="text">溯源</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <pack-detail ref="pack"></pack-detail>
    <page-timeline ref="timeline"></page-timeline>
  </div>
</template>
<script>
import commTable from "@/mixins/commTable";
import commonTable from "@/components/common/commonTable.vue";
import {get} from "@/utils/request";
import PackDetail from "@/pages/dashboard/components/detailDialog/PackDetail.vue";
import PageTimeline from "@/pages/dashboard/components/detailDialog/PageTimeline.vue";

export default {
  components: {PageTimeline, PackDetail, commonTable},
  mixins: [commTable],
  props:{
    searchConfig:{
       type: Object,
      default:()=> {
        return {
          showWare:true,
          showStatus: true
        }
      }
    }
  },
  data() {
    return {
      warehouseList: [],
      classList: [],
      statusList: {1: '未出库', 2: "已出库"},
    }
  },
  mounted() {
    this.url = "/crk/app/warehouseDetail"
    this.initClassList()
    this.initWarehouseList()
  },
  methods: {

    tableRowClassName({row, rowIndex}) {
      if (row.outTime) {
        return 'out-row';
      }
      return '';
    },
    initWarehouseList() {
      get('/crk/app/warehouseTree').then(res=>{
        this.warehouseList = res
        this.queryParam.status = 1
      })
    },
    search() {

    },
    initClassList() {
      get('/pageClass/tree', {type: 0}).then(res => {
        this.classList = res
      })
    },
    showDetail(row,status) {
        if(!row.isBox) {
          return
        }
        this.$refs.pack.showDialog();
        var param={boxId:row.pageHandleId}
        if(status){
          param.status=status
        }
        this.$refs.pack.initList(param)
    },
    detail(row){
       if(row.isBox){
         this.$refs.timeline.showBox(row.pageHandleId)
       }else {
         this.$refs.timeline.show(row.pageHandleId)
       }
    },
    selectWarehouse(row){
      if( this.queryParam.warehouseId ){
        delete this.queryParam.warehouseId
      }
      if( this.queryParam.regionId ){
        delete this.queryParam.regionId
      }
      if( this.queryParam.localId ){
        delete this.queryParam.localId
      }

      switch (row.length){
        case 1:
          this.queryParam.warehouseId = row[0]
          break;
        case 2:
          this.queryParam.regionId = row[1]
          break;
        case 3:
          this.queryParam.localId = row[2]
          break;
      }
    }
  }
}
</script>
<style scoped>
  @import "../../../../static/css/crk.css";
  .el-form--inline{
    margin-top: 10px!important;
  }
</style>
