<template>
  <div>
    <comm-dialog title="箱详情" ref="dialog" :show-fotter="false">
      <template slot="content">
        <el-table :data="tableData" :row-class-name="tableRowClassName">
          <el-table-column prop="handle" label="标识" width="300">
            <template slot-scope="scope">
              <span>{{ scope.row.handle }}</span>
              <span :class="scope.row.isBox ? 'box' : 'pack'">{{ scope.row.isBox ? '箱' : '盒' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="className" label="类型"  align="center"/>
<!--          <el-table-column prop="outTime" label="出库时间"  align="center"/>-->
<!--          <el-table-column prop="dealerName" label="代理商"  align="center"/>-->
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
               <el-button @click="detail(scope.row)" type="text">溯源</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </comm-dialog>
    <page-timeline ref="timeline"/>
  </div>

</template>
<script>
import CommDialog from "@/components/common/CommDialog.vue";
import commTable from "@/mixins/commTable";
import {get} from "@/utils/request";
import PageTimeline from "@/pages/dashboard/components/detailDialog/PageTimeline.vue";

export default {
  components: {CommDialog,PageTimeline},
  mixins: [commTable],
  data(){
    return {

    }
  },
  methods:{
    tableRowClassName({row, rowIndex}) {
      if (row.status===2) {
        return 'out-row';
      }
      return '';
    },
    showDialog(){
       this.$refs.dialog.dialogShow=true
    },
    initList(param){
        get('/crk/app/packDetail',param).then(res=>{

            this.tableData=res
          console.log('tableData',this.tableData)
        })
    },
    detail(row){
      this.$refs.timeline.show(row.pageHandleId)
    }
  }
}
</script>
<style scoped>
@import "../../../../static/css/crk.css";
</style>
